import { computed, InjectionKey, provide } from 'vue';

import { SponsoredRecommendationsInput, SraPlacement } from '@/@types/graphql-types';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { ObjectType, SraFormat } from '@/@types/graphql-types';
import { Platform } from '@/@types/graphql-types';
import { TrackingAppId, ApplicationContext } from '@/helpers/tracking/tracking-helper';
import { useLanguageStore } from '@/helpers/composables/useStores';
import { getSrDebuggerInfo } from '@/helpers/sponsoredrec-debugging-tools';

interface UseSponsoredRecOptions {
	pageType: string;
	placement: SraPlacement;
	testingMode?: boolean;
}

export const SR_PROVIDER_KEY = Symbol() as InjectionKey<SraPlacement>;

export const supportedFormats = [SraFormat.Image, SraFormat.Video];
export const supportedObjectTypes = [
	ObjectType.Movie,
	ObjectType.Show,
	ObjectType.GenericTitleList,
	ObjectType.ShowSeason,
];

const recordSRPlacement = new Map<SraPlacement, SponsoredAdFragment>();

export function useSponsoredRec({ pageType, placement, testingMode = false }: UseSponsoredRecOptions) {
	const { language, country } = useLanguageStore();
	provide(SR_PROVIDER_KEY, placement);

	const srDebuggerInfo = getSrDebuggerInfo();

	const allowSponsoredRecommendations = computed<SponsoredRecommendationsInput>(() => ({
		pageType,
		placement,
		language: language.value,
		country: country.value,
		applicationContext: ApplicationContext, // now with appContext
		appId: TrackingAppId,
		platform: Platform.Web,

		// Have every SR Placement supoort every format
		supportedFormats,
		supportedObjectTypes,

		geoCountry: srDebuggerInfo?.geoCountry,
		testingModeCampaignName: srDebuggerInfo?.campaignName,
		testingModeForceHoldoutGroup: !!srDebuggerInfo?.holdoutGroup,
		testingMode: srDebuggerInfo?.testingMode || testingMode,
	}));

	return {
		allowSponsoredRecommendations,
		saveSrPlacement: (sponsoredAd: SponsoredAdFragment | undefined) => {
			if (!sponsoredAd) return;
			recordSRPlacement.set(placement, sponsoredAd);
		},
	};
}

export const getSrPlacement = (placement: SraPlacement): SponsoredAdFragment | undefined => {
	return recordSRPlacement.get(placement);
};

export const getSrPlacementFromBidId = (bidId: string): SraPlacement | undefined => {
	let placement: SraPlacement | undefined;
	recordSRPlacement.forEach((value, key) => {
		if (bidId === value.bidId) {
			placement = key;
		}
	});
	return placement;
};

export const getSrFromBidId = (
	bidId: string
): { placement: SraPlacement | undefined; srFragment: SponsoredAdFragment | undefined } => {
	let placement: SraPlacement | undefined;
	let srFragment: SponsoredAdFragment | undefined;
	recordSRPlacement.forEach((value, key) => {
		if (bidId === value.bidId) {
			placement = key;
			srFragment = value;
		}
	});
	return { placement, srFragment };
};
